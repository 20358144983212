import React, { useState } from 'react'
import './landing/main-banner/banner.scss'
import { useParams } from 'react-router';
import CopyToClipboard from 'react-copy-to-clipboard';

function Newbanner() {
    const params = useParams();
    console.log(params, 'params');
const [copied, setCopied] = useState(false);
    const textCopiedFun = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1500)
    }
    return (
        <>
            <section className="main-reffrel main_main_main">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-sm-3"></div> */}
                        <div className="col-sm-7 m-auto">
                            <div className="inner-logo text-center mb-3">
                                {/* <img src="/logo_logo.svg" alt="" className="img-fluid mb-4" /> */}
                                <img src='\mainlogo.svg' alt='img'className='img-fluid'/>
                            </div>
                            <div className="text-center">
                                <h6 className='first_first_text my-3'>You have been referred to download the Legion Network Super App.</h6>
                                {/* <h6 className='first_first_text my-3'>Please sign up using the following referral code to earn $5 (34 LGX) :</h6> */}
                                <p className='ref_ref_ref '>Copy This Referral Code:</p>
                                {/* <div className='main_main_div_copy'>
                                    <button className='code_btn_btn my-4'>{params?.id}</button>
                                    {
                                        copied ?
                                            <CopyToClipboard text={params?.id}

                                            >
                                                <div className="sjdbsjx">
                                                                        <div className="sjsjbsd">
                                                                            <img alt="" src="/bluemoon-nft/profiles/copy-icon.svg" className="img-fluparams?.id" />
                                                                        </div>
                                                                        <span>Copied</span>
                                                                    </div>

                                                <span className="ml-3 text-white">Copied</span>


                                            </CopyToClipboard>
                                            :
                                            <CopyToClipboard text={params?.id} onCopy={textCopiedFun}>
                                                <div className="ml-3 text-white copsssssssss">
                                                    
                                                    <img alt="" src="/bluemoon-nft/profiles/copy-icon.svg" className="img-fluid" />
                                                </div>

                                            </CopyToClipboard>
                                    }
                                </div> */}
                                <div className='mainparent'>
                                <input type='text'placeholder='0KnH0Aj' value={params?.id}/>
                                <span className="inputspan">Referral code</span>
                                    <CopyToClipboard text={params?.id} onCopy={textCopiedFun}>
                                         <button className='copy'>{copied ? 'Copied' :'Copy'}</button> 
                                         </CopyToClipboard>
                                </div>
                                <p className='ref_ref_ref jsjdhdjh blue'>Please download the Legion App to sign up</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="d-flex justify-content-center align-items-center">
                                <a href='https://play.google.com/store/apps/details?id=com.legion.production' rel="noopener noreferrer" target='_blank' className="btn shadow-none">
                                    <img src="\Button22.svg" alt="" className="img-fluid" />
                                </a>
                                <a href='https://apps.apple.com/gb/app/legion-network/id1602921528 ' rel="noopener noreferrer" target='_blank' className="btn shadow-none">
                                    <img src="\Button23.svg" alt="" className="img-fluid" />
                                </a>
                            </div>
                            {/* <div className="d-flex justify-content-center align-items-center ptb20">Already have an account ? <Link to="/"> Login</Link></div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Newbanner
