import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Banner from './components/landing/main-banner/Banner';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Newbanner from './components/Newbanner';
// import { useParams } from 'react-router';

function App() {
  return (
    <>
          <Router>
          <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
            <Switch>
              <Route exact  path={'/:id'} component={Newbanner} />
              {/* <Route exact  path='/' component={Banner} /> */}
              {/* <Route  exact path='/:id' component={Banner} /> */}
            </Switch>
          </Router>
    </>
  );
}

export default App;
